type ResourceRenderFunction = (resource:keyof typeof ResourceTypes) => string;
interface ResourceType {
    path: (namespace?:string) => string;
    hidden?: boolean,
    columns: Record<string, ResourceRenderFunction>
}

const ResourceTypes:Record<string, ResourceType> = {
    "Namespaces" : {
        path: () => "/api/v1/namespaces",
        hidden: true,
        columns: {
            "Name": (resource:any) => resource?.metadata?.name
        }
    },
    "Deployments" : {
        path: (namespace?:string) => `/apis/apps/v1${namespace && `/namespaces/${namespace}`}/deployments`,
        columns: {
            "Namespace": (resource:any) => resource?.metadata?.namespace,
            "Name": (resource:any) => resource?.metadata?.name,
            "Containers": (resource:any) => (resource?.spec?.template?.spec?.containers || []).map((container:any) => container?.name).join(","),
            "Replicas": (resource:any) => `${resource?.status?.readyReplicas || 0}/${resource?.spec?.replicas}`
        }
    },
    "Pods" : {
        path: (namespace?:string) => `/api/v1${namespace && `/namespaces/${namespace}`}/pods`,
        columns: {
            "Namespace": (resource:any) => resource?.metadata?.namespace,
            "Name": (resource:any) => resource?.metadata?.name,
            "Containers": (resource:any) => (resource?.spec?.containers || []).map((container:any) => container?.name).join(","),
            "Status": (resource:any) => resource?.status?.phase,
        }
    },
    "Services": {
        path: (namespace?:string) => `/api/v1${namespace && `/namespaces/${namespace}`}/services`,
        columns: {
            "Namespace": (resource:any) => resource?.metadata?.namespace,
            "Name": (resource:any) => resource?.metadata?.name,
            "Target Port": (resource:any) => (resource?.spec?.ports || []).map((port:any) => port?.targetPort).join(","),
            "Port": (resource:any) => (resource?.spec?.ports || []).map((port:any) => port?.port).join(","),
        }
    },
    "CronJobs": {
        path: (namespace?:string) => `/apis/batch/v1${namespace && `/namespaces/${namespace}`}/cronjobs`,
        columns: {
            "Namespace": (resource:any) => resource?.metadata?.namespace,
            "Name": (resource:any) => resource?.metadata?.name,
            "Schedule": (resource:any) => resource?.spec?.schedule,
            "Enabled": (resource:any) => resource?.spec?.suspend ? "No" : "Yes",
            "TimeZone": (resource:any) => resource?.spec?.timeZone,
            "Last Successful Run": (resource:any) => resource?.status?.lastSuccessfulTime,
        }
    },
    "ConfigMaps": {
        path: (namespace?:string) => `/api/v1${namespace && `/namespaces/${namespace}`}/configmaps`,
        columns: {
            "Namespace": (resource:any) => resource?.metadata?.namespace,
            "Name": (resource:any) => resource?.metadata?.name,
            "Values": (resource:any) => resource?.data && Object.keys(resource?.data).length || 0
        }
    }
};

export type { ResourceRenderFunction, ResourceType };
export { ResourceTypes };
